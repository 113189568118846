"use client";

import { useEffect, useRef, useState } from "react";
import ReactLazy from "react-lazyload";
import { useInView } from "react-intersection-observer";
import { gtmEvent } from "utils/gtm";
import { cn } from "../../utils";
import { useMounted } from "../../hooks/use-mounted";
import { useVisibility } from "../../hooks/use-visibility";
import { KevelWaterfall } from "./kevel-waterfall";

export interface AdType {
  networkCode: string;
  adUnit: string;
  targetingArguments?: Record<string, string[] | string>;
}

export interface AdProps extends AdType {
  name: string;
  id?: string;
  height?: number;
  width?: number;
  className?: string;
  mobile?: boolean;
  tablet?: boolean;
  desktop?: boolean;
  largeDesktop?: boolean;
  sticky?: boolean;
  biggerStickyTop?: boolean;
  largeStickyTop?: boolean;
  useRelativeForSticky?: boolean;
  isTwitchLive?: boolean;
  useKevelFallback?: boolean;
  offset?: number;
  sizes: googletag.GeneralSize;
}

const StickyWrapper = ({
  sticky,
  biggerStickyTop,
  largeStickyTop,
  isTwitchLive,
  children,
}: {
  sticky: boolean;
  biggerStickyTop?: boolean;
  largeStickyTop?: boolean;
  isTwitchLive?: boolean;
  children: React.ReactNode;
}) =>
  sticky ? (
    <div
      className={cn("relative", {
        "sticky top-16 md:top-24": sticky,
        "top-24": biggerStickyTop,
        "top-36": biggerStickyTop && isTwitchLive,
        "top-52": largeStickyTop,
      })}
    >
      {children}
    </div>
  ) : (
    children
  );

const Ad = ({
  height,
  width,
  className,
  sizes,
  mobile,
  desktop,
  largeDesktop,
  tablet,
  sticky = false,
  biggerStickyTop = false,
  largeStickyTop = false,
  useRelativeForSticky = false,
  isTwitchLive = false,
  offset = 100,
  ...rest
}: AdProps) => {
  const mounted = useMounted();
  const [renderFallback, setRenderFallback] = useState(false);
  const heightStyle = typeof height !== "undefined" ? `${height}px` : "100%";
  const widthStyle = typeof width !== "undefined" ? `${width}px` : "100%";

  const stickyContainerStyle =
    sticky && width
      ? {
          top: "0",
          bottom: "0",
          width: widthStyle,
          left: `${0 - width}px`,
        }
      : {};

  if (!mounted)
    return (
      <div
        style={{ width: widthStyle, height: heightStyle }}
        className={cn(
          {
            "hidden min-[1500px]:flex": largeDesktop,
            "hidden lg:flex": desktop,
            "hidden md:flex lg:hidden": tablet,
            "flex md:hidden": mobile,
          },
          { absolute: sticky },
          className
        )}
      />
    );

  return (
    <ReactLazy
      placeholder={<div style={{ width: widthStyle, height: heightStyle }} />}
      className={cn(
        "relative mx-auto flex items-center justify-center",
        {
          "hidden min-[1500px]:flex": largeDesktop,
          "hidden lg:flex": desktop,
          "hidden md:flex lg:hidden": tablet,
          "flex md:hidden": mobile,
        },
        { "absolute h-full items-start": sticky },
        { " relative": useRelativeForSticky },
        { "w-full": sizes === "fluid" || renderFallback },
        className
      )}
      offset={offset}
      style={stickyContainerStyle}
      once
    >
      <StickyWrapper
        sticky={sticky}
        biggerStickyTop={biggerStickyTop}
        isTwitchLive={isTwitchLive}
        largeStickyTop={largeStickyTop}
      >
        <AdSlot
          height={height}
          width={width}
          sizes={sizes}
          {...rest}
          onRenderFallback={setRenderFallback}
        />
      </StickyWrapper>
    </ReactLazy>
  );
};

const AdSlot = ({
  adUnit,
  networkCode,
  sizes,
  width,
  height,
  name,
  id,
  useKevelFallback,
  targetingArguments,
  onRenderFallback,
}: AdProps & { onRenderFallback: (fallback: boolean) => void }) => {
  const [renderFallback, setRenderFallback] = useState(false);
  const [slot, setSlot] = useState<googletag.Slot | null>(null);
  const [visibleTime, setVisibleTime] = useState(0);
  const [hasRefreshed, setHasRefreshed] = useState(false);
  const [impressionEventFired, setImpressionEventFired] = useState(false);
  const refreshInterval = useRef<NodeJS.Timeout>();
  const { ref, inView } = useInView({ threshold: 0.5 });
  const tabActive = useVisibility();

  const divId = id ? `${name}_${id}` : name;
  const heightStyle = typeof height !== "undefined" ? `${height}px` : "100%";
  const widthStyle = typeof width !== "undefined" ? `${width}px` : "100%";

  useEffect(() => {
    if (typeof window === "undefined") return;

    const setTargeting = (s: googletag.Slot) => {
      if (!targetingArguments) return;

      Object.keys(targetingArguments).forEach((key) => {
        const value = targetingArguments[key];
        if (value) s.setTargeting(key, value);
      });
    };

    let adSlot: googletag.Slot | undefined;
    if (!googletag.apiReady) return;

    window.googletag.cmd.push(() => {
      adSlot = googletag
        .defineSlot(`/${networkCode}/${adUnit}/${name}`, sizes, divId)
        ?.addService(googletag.pubads());

      if (adSlot) {
        if (targetingArguments) setTargeting(adSlot);
        setEvents(adSlot);
      }

      googletag.pubads().setCentering(true);
      googletag.enableServices();
      googletag.display(divId);
    });

    return () => {
      if (adSlot) googletag.destroySlots([adSlot]);
    };
  }, []);

  useEffect(() => {
    if (!slot || renderFallback) return;

    if (inView && tabActive) {
      if (visibleTime === 1 && !impressionEventFired) {
        gtmEvent("visible_impression", { adunit: slot.getAdUnitPath() });
        setImpressionEventFired(true);
      }

      if (visibleTime >= 30) {
        if (!hasRefreshed) {
          googletag.pubads().refresh([slot]);
          setHasRefreshed(true);
          setVisibleTime(0);
          setImpressionEventFired(false);
        } else {
          clearInterval(refreshInterval.current);
        }
      } else {
        refreshInterval.current = setInterval(() => {
          setVisibleTime((prev) => prev + 1);
        }, 1000);
      }
    } else {
      clearInterval(refreshInterval.current);
    }

    return () => {
      clearInterval(refreshInterval.current);
    };
  }, [
    slot,
    inView,
    visibleTime,
    impressionEventFired,
    tabActive,
    hasRefreshed,
    renderFallback,
  ]);

  const setEvents = (targetSlot: googletag.Slot) => {
    googletag.pubads().addEventListener("slotRenderEnded", (event) => {
      if (event.slot === targetSlot) {
        setSlot(targetSlot);

        if (event.isEmpty && useKevelFallback) {
          setRenderFallback(true);
          onRenderFallback(true);
        }
      }
    });
  };

  return (
    <>
      {!slot && !renderFallback && (
        <div style={{ width: widthStyle, height: heightStyle }} />
      )}
      {!renderFallback && (
        <div
          ref={ref}
          id={divId}
          className={cn({ "w-full": sizes === "fluid" })}
        />
      )}
      {Boolean(renderFallback) && (
        <KevelWaterfall style={{ width: widthStyle, height: heightStyle }} />
      )}
    </>
  );
};

export { Ad };
